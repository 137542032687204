import React from 'react'
import { useState, useEffect } from 'react';
import { dashboard_nav } from '../data/dashboard';
import logo from '../images/logo/DAS_Tuning_Logo.png'
import { Link } from 'react-router-dom';
import {  BsBoxArrowLeft, BsGraphUp, BsSearch, BsBoxArrowUpRight } from "react-icons/bs";
export const DashNavVertical = () => {
    const [redirectTo, setRedirectTo] = useState(null);
  
    useEffect(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, [redirectTo]);
    const handleClick = () => {
      window.open('https://analytics.google.com/analytics/web/#/p384070561/reports/intelligenthome', '_blank');
    };
   const  handleClickConsole   = () => {
      window.open('https://search.google.com/search-console/welcome', '_blank');
    };
    const handleLogout = () => {
    
      localStorage.removeItem('userToken'); 
  
      // Redirect to login page
      setRedirectTo('/admin-login');
    };
  return (
    <div className="lg:w-3/12   max-h-screen 2xl:w-2/12 box-shadow sticky top-0 bg-normalWhite overflow-auto">

     <div className="row flex flex-col">
          <div className="sm:py-4 lg:pt-16 pt-5 sm:ps-8">
          <img src={logo} alt="" className='lg:w-3/4 w-3/12 '/>
          </div>
              {dashboard_nav.map((item)=>(
              <Link className="flex gap-3 py-2 items semibold ps-4" role='button'  
              to={item.onclick}>
              <h1 className='semibold my-auto' style={{fontSize:"20px"}}>{item.icon}</h1>
              <h6 className='my-auto'>{item.text}</h6>
              </Link>
              ))}  
                <Link className="flex gap-3 py-2 items semibold ps-4" role='button' 
           onClick={handleClick}
             >
              <h1 className='semibold my-auto' style={{fontSize:"20px"}}><BsGraphUp/></h1>
              <h6 className='my-auto'>Analytics</h6>
              <h1 className='semibold my-auto'><BsBoxArrowUpRight/></h1>
              </Link>
              <Link className="flex gap-3 py-2 semibold ps-4" role='button' 
             onClick={handleClickConsole}
             >
                <h1 className='my-auto semibold' style={{fontSize:"20px"}}><BsSearch/></h1>
      <h6 className='my-auto'>Search Console</h6>
      <h1 className='my-auto semibold'><BsBoxArrowUpRight/></h1>
          
              </Link>
              <Link className="flex gap-3 py-3  absolute bottom-4 semibold ps-4" role='button' 
           onClick={handleLogout}
             >
            <h1 className='my-auto ' style={{fontSize:"20px"}}><BsBoxArrowLeft/></h1>
              <h6 className='my-auto'>Logout</h6>
              </Link>
          </div>
</div>
  )
}

 

export const Topbar = () => {
    return (
      <div className="sticky top-0 z-50 w-full bg-normalWhite box-shadow">
      <div className="flex text-start   text-dasBlack py-8 p-4">
            <p>Welcome back</p>
        </div>
    </div>
    );
};

 

 