
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ContactDisplay from './frontend/contact-form/ContactDisplay';
import ContactForm from './dashboard/contactus/ContactFormAdd';
import AdminLogin from './dashboard/login/AdminLogin';
import CreatePost from './dashboard/blogs/CreatePost';
import Article from './frontend/blogs/Article';
import DisplayOneBlog from './frontend/blogs/DisplayOneBlog';
import DisplayAllBlog from './frontend/blogs/DisplayAllBlogs';
 import DashboardHome from './dashboard/DashboardHome';
import Requests from './dashboard/contactus/Requests';
import BlogsHome from './dashboard/blogs/BlogsHome';
import AddBlog from './dashboard/blogs/AddBlog';
import UpdateBlogPost from './dashboard/blogs/UpdateBlogPost';
import UpdateTest from './dashboard/blogs/UpdateTest';
import RecentEnquiries from './dashboard/contactus/RecentEnquiries';
import OtpVerification from './dashboard/login/Otpverification';
  function App() {
  return (
    <div className='font-poppins  bg-dasOffWhite' >

    <Router>
    <Routes> 
    <Route path="/admin-login" element= {<AdminLogin   />} />
    <Route path="/admin-login/c2VyIn0sImlhdCI6MTY4ODY0MTUzOC" element= {<OtpVerification   />} />
   {/** dashboard */} 
   <Route path="/adminpanel" element= {<DashboardHome  />} />
   <Route path="/adminpanel/enquiries" element= {<Requests  />} />
   <Route path="/adminpanel/blogs" element= {<BlogsHome />} />
   <Route path="/adminpanel/blogs/add-blogpost" element= {<AddBlog />} />
    <Route path="/adminpanel/blogs/:id/edit" element={<UpdateBlogPost />} /> 

     {/** blog  */} 
    <Route path="/adminpanel/add-blog" element= {<CreatePost   />} />
    <Route path="/adminpanel/update-test/:id" element= {<UpdateTest  />} />
    <Route path="/article" element= {<Article   />} />
    <Route path="/blogs" element= {<DisplayAllBlog  />} />
    <Route path="/posts/:id" element= {<DisplayOneBlog   />} />

     {/**contcat  */} 
     <Route path="/contact-form" element={<ContactForm/>} />
     <Route path="/contact-form/recent" element={<RecentEnquiries/>} />
    <Route path="/adminpanel/contact-display" element={<ContactDisplay/>} />
      </Routes>
    </Router>
    </div>
  );
}

export default App;
