import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { BsArrowRightShort, BsPencilSquare  } from "react-icons/bs";
 import { Link } from 'react-router-dom';

const RecentBlogs = () => {
    const [redirectTo, setRedirectTo] = useState(null);
    const [blogs, setBlogs] = useState([]);
    useEffect(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, [redirectTo]);
    
    const handleClickOpen = () => {
      setRedirectTo("/adminpanel/blogs");
    };
    useEffect(() => {
      const fetchEnquiries = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/blog/recent`);
          setBlogs(response.data);
        } catch (error) {
          console.error('Failed to fetch enquiries:', error);
        }
      };
      fetchEnquiries();
    }, [])
  return (
    <div>
           <div className='flex justify-between'>
                            <h2 className='my-auto font-bold'>Blogs</h2>
                            <h2 className='my-auto cursor-pointer' style={{fontSize:"24px"}}  onClick={handleClickOpen}>
                                <BsArrowRightShort/></h2>
                            </div>
                            <div>
                            { blogs.length === 0 ? (
                                  <p>No recent blogs.</p>
                               ) : (
                            blogs.map((blog, index) => (
                     <div className="flex flex-row gap-x-4 my-4 py-2 bg-dasOffWhite" key={index}>
                        <div className="w-3/12">
                        <img src={`${process.env.REACT_APP_API_URL}/${blog.mainBanner}`} alt="" className='rounded-lg'/>
                        </div>
                        <div className="w-8/12 flex flex-col my-auto">
                        <h6 className='font-semibold'>{ blog.title}</h6>
                        <div className="flex flex-row justify-between">
                        <p className='my-auto text-dasGrayDark'>03.Apr.2023</p>
                        <button>
                        <Link to={`/adminpanel/blogs/${blog._id}/edit`} className='my-auto'>
                        <BsPencilSquare/>
                        </Link></button>
                        </div>
                        </div>
                        <div>

                        </div>
                     </div>
                     ))
                     )}
                     </div>
    </div>
  )
}

export default RecentBlogs
