import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ContactDisplay = () => {
    const [contacts, setContacts] = useState([]);

    useEffect(() => {
      const fetchContacts = async () => {
        try {
          const response = await axios.get('/api/contact');
          setContacts(response.data);
        } catch (error) {
          console.error('Failed to fetch contacts.');
        }
      };
  
      fetchContacts();
    }, []);
  
    return (
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Contact Number</th>
            <th>Message</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact._id}>
              <td>{contact.name}</td>
              <td>{contact.email}</td>
              <td>{contact.contactNumber}</td>
              <td>{contact.message}</td>
              <td>{new Date(contact.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

export default ContactDisplay
