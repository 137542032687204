
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { BsArrowRightShort, BsPencilSquare  } from "react-icons/bs";
const RecentEnquiries = () => {
    const [enquiries, setEnquiries] = useState([]);
    const [redirectTo, setRedirectTo] = useState(null);
    useEffect(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, [redirectTo]);
    
 
    const handleClickOpenRequest = () => {
      setRedirectTo("/adminpanel/enquiries");
    };
  useEffect(() => {
    const fetchEnquiries = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contact/recent`);
        setEnquiries(response.data);
      } catch (error) {
        console.error('Failed to fetch enquiries:', error);
      }
    };

    fetchEnquiries();
  }, []);

  return (
    <div>
     <div className='flex justify-between'>
                            <h2 className='my-auto font-bold'>Requests</h2>
                            <h1 className='my-auto cursor-pointer'style={{fontSize:"24px"}}
                            onClick={handleClickOpenRequest}>
                                <BsArrowRightShort/></h1>
                            </div>
                            <div>
                            {enquiries.length === 0 ? (
                                  <p>No recent enquiries.</p>
                               ) : (
                                  enquiries.map((enquiry, index) => (
                                   <div key={index} className='my-5 bg-dasOffWhite p-1'>
                                <h3 className='text-dasGrayDark font-bold' >{enquiry.name}</h3>
                                <p className='text-dasGrayDark'>{enquiry.message}  </p>
                                </div>
                                  ))
                               )}
                            </div>
  </div>
  )
}

export default RecentEnquiries
