import React, { useState } from 'react';
import axios from 'axios';

const CreatePost = () => {
  const [title, setTitle] = useState('');
  const [mainParagraph, setMainParagraph] = useState('');
  const [subParagraph, setSubParagraph] = useState('');
  const [altText, setAltText] = useState('');
  const [images, setImages] = useState([]);
  const [altTextSecondary, setAltTextSecondary] = useState('');
  const [metaTitle, setMetaTitle] = useState('');
  const [metaDescription, setMetaDescription] = useState('');
  const [metaKeywords, setMetaKeywords] = useState('');
  const [youtubeVideo, setYoutubeVideo] = useState('');
  const [mainBanner, setMainBanner] = useState(null);
  const [sections, setSections] = useState([{ subTitle: '', paragraph: '', sectionImage: null }]);
  
  const handleChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'title':
        setTitle(value);
        break;
      case 'mainParagraph':
        setMainParagraph(value);
        break;
      case 'subParagraph':
        setSubParagraph(value);
        break;
      case 'altText':
        setAltText(value);
        break;
      case 'altTextSecondary':
        setAltTextSecondary(value);
        break;
      case 'metaTitle':
        setMetaTitle(value);
        break;
      case 'metaDescription':
        setMetaDescription(value);
        break;
      case 'metaKeywords':
        setMetaKeywords(value);
        break;
      case 'youtubeVideo':
        setYoutubeVideo(value);
        break;
        case 'sectionTitle':
            setSections([
              {
                subTitle: value,    
                paragraph: sections[0].paragraph  
              }
            ]);
            break;
        case 'sectionBody':
            setSections([
              {
                subTitle: sections[0].subTitle,  
                paragraph: value  
              }
            ]);
            break;
      default:
        break;
    }
  };
  const handleSectionChange = (event, index) => {
    const newSections = [...sections];
    if (event.target.name === 'sectionTitle') {
      newSections[index].subTitle = event.target.value;
    } else if (event.target.name === 'sectionBody') {
      newSections[index].paragraph = event.target.value;
    }
    setSections(newSections);
  };
  const handleSectionImageChange = (event, index) => {
    const newSections = [...sections];
    newSections[index].sectionImage = event.target.files[0];
    newSections[index].sectionImageFieldname = `sections[${index}][sectionImage]`;  
    setSections(newSections);
  };
  const handleAddMore = () => {
    setSections([...sections, { subTitle: '', paragraph: '', sectionImage: null }]);
  };

  const handleMainBannerChange = (event) => {
    const file = event.target.files[0];
    setMainBanner(file || '');
  };
  const handleImageChange = (event) => {
    setImages([...event.target.files])
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
  
    formData.append('main_banner', mainBanner);
    formData.append('title', title);
    formData.append('mainParagraph', mainParagraph);
    formData.append('subParagraph', subParagraph);
    formData.append('altText', altText);
    formData.append('altTextSecondary', altTextSecondary);
    formData.append('metaTitle', metaTitle);
    formData.append('metaDescription', metaDescription);
    formData.append('metaKeywords', metaKeywords);
     formData.append('youtubeVideo', youtubeVideo);

     for (let i = 0; i < sections.length; i++) {
      if (sections[i].sectionImage) {
        formData.append(`sections[${i}][sectionImage]`, sections[i].sectionImage);
        // Exclude sectionImage from the JSON stringified section
        const {sectionImage, ...sectionWithoutImage} = sections[i];
        formData.append(`sections[${i}]`, JSON.stringify(sectionWithoutImage));
      } else {
        formData.append(`sections[${i}]`, JSON.stringify(sections[i]));
      }
      console.log('Sections:', sections);

    }

    for (let i = 0; i < images.length; i++) {
      formData.append('images', images[i]);
    }
    try {
      await axios.post('/api/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Blog Post created successfully!');
      setTitle('');
      setMainParagraph('');
      setSubParagraph('');
      setMainBanner('');
      setImages([]);
      setAltText('');
      setAltTextSecondary('');
       setMetaTitle('');
      setMetaDescription('');
      setMetaKeywords('');
      
    }catch (err) {
      console.error(err);
      console.error(err.response.data); // Log the detailed error response from the server
      alert('Error creating post');
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" name="title" placeholder='Title' value={title} onChange={handleChange} required/>
      <textarea name="mainParagraph" value={mainParagraph} placeholder='mainParagraph' onChange={handleChange} required/>
      <textarea name="subParagraph" placeholder='subParagraph' value={subParagraph} onChange={handleChange} />
      <input type='file' id='fileInput' name="main_banner" className="p-md-3 p-2 w-100  mb-md-4 mb-3"required  onChange={handleMainBannerChange}   />      
  
      <input type="text" name="altText" value={altText} onChange={handleChange} placeholder='alt text'/>
      <input type="text" name="altTextSecondary" value={altTextSecondary} onChange={handleChange} placeholder='alt 2'/>
      <input type="text" name="metaTitle" value={metaTitle} onChange={handleChange} placeholder='meta title'/>
      <textarea name="metaDescription" value={metaDescription} onChange={handleChange} placeholder='meta descrption'/>
      <input type="text" name="metaKeywords" value={metaKeywords} onChange={handleChange} placeholder='keywords'/>
      <input  placeholder='Banner'  className="py-md-3 px-md-1 px-0 p-2 mb-4 w-100" type="file" name="images" multiple onChange={handleImageChange}   />
      <input type="text" name="youtubeVideo" value={youtubeVideo} onChange={handleChange} placeholder='youtube link'/>
      {
        sections.map((section, index) => (
          <div key={index}>
            <input
              type="text"
              name="sectionTitle"
              placeholder='subtitle'
              value={section.subTitle}
              onChange={(event) => handleSectionChange(event, index)}
            />
            <textarea
              name="sectionBody"
              placeholder='paragraph'
              value={section.paragraph}
              onChange={(event) => handleSectionChange(event, index)}
            />
        <input
  type="file"
  name={`sections[${index}][sectionImage]`}
  onChange={(event) => handleSectionImageChange(event, index)}
/>

          </div>
        ))
      }
      <button type="button" onClick={handleAddMore}>Add More Content</button>

      <button type="submit">Create Post</button>
    </form>
  );
};

export default CreatePost;
