import React, { useState } from "react";
import axios from "axios";
import './admin-login.css'
import { adminlogin } from "../../data/admin-login";
import imageUrl from '../../images/login/Porsche-Hero-Desk.jpg'
import logo from '../../images/logo/DAS_Tuning_Logo.png'
import { BsExclamationTriangle } from "react-icons/bs";
const OtpVerification = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);

  const handleSendOTP = (event) => {
    event.preventDefault();
    setPhoneError(null);  // Clear any previous errors
    axios.get(`${process.env.REACT_APP_API_URL}/sendOTP`, {
      params: {
        phonenumber: phoneNumber,
        channel: 'sms'
      }
    })
    .catch((err) => {
      // Assuming that status code 400 means incorrect phone number
      if(err.response && err.response.status === 400) {
        setPhoneError('Mobile number is wrong');
      } else {
        setPhoneError('Something went wrong');
      }
    });
  }

  const handleVerifyOTP =(event)=>{
    event.preventDefault();
    setOtpError(null);  // Clear any previous errors
    axios.get(`${process.env.REACT_APP_API_URL}/verifyOTP`, {
      params:{
        phonenumber: phoneNumber,
        code: otp
      }
    })
    .then((response) => {
      if (response.status === 200) {
        window.location.href = '/adminpanel';
      }
    })
    .catch((err) => {
      // Assuming that status code 400 means incorrect OTP
      if(err.response && err.response.status === 400) {
        setOtpError('OTP is wrong');
      } else {
        setOtpError('Something went wrong');
      }
    });
  }

  return (
   <>
   {adminlogin.map((item)=>(
    <section className="admin-login " 
    style={{ backgroundImage: `url(${imageUrl})` }}
    >    
    <div className="container  p-3 md:p-5">
    <div className=" login-card p-8 ">
  
     <img src={logo} alt="" className="mx-auto pb-3 md:pb-6 xl:w-3/4 2xl:w-2/4"/> 
      <form className="mx-auto">
        <div>
          <input
            type="text"
            className="md:p-5 p-3   sm:w-72"
            placeholder="Enter phone number"
            value={phoneNumber} onChange={(event) => setPhoneNumber(event.target.value)}
          />
        </div>
        {phoneError && <div  className="text-red-500 mt-2  flex py-2 bg-white w-10/12 border border-dasRed rounded-lg">
         <h4  className="mt-1 px-2 "> <BsExclamationTriangle/></h4> <div >{phoneError}</div>  </div>}
      <button  
        onClick={(event) => handleSendOTP(event)}
        className="border-2  text-lg text-dasRed border-dasRed  w-full mt-3 md:p-4 p-3 sm:w-72 bg-white rounded-xl text-dasBlck font-bold"> 
        Send OTP 
      </button>

        <div>
          <input
            value={otp} onChange={(event) => setOtp(event.target.value)}
            placeholder="OTP"
            className="md:p-5 p-3 md:mb-1 mb-2 my-10 sm:w-72 bg-white rounded-lg"
        
          />
        </div>
         
        {otpError && <div className="text-red-500 mb-3  flex py-2 bg-white w-10/12 border border-dasRed rounded-lg">
        <h4  className="mt-1 px-2 "> <BsExclamationTriangle/></h4> <div >{otpError}</div>  </div>}
      <button 
        onClick={(event) => handleVerifyOTP(event)}
        className="w-full md:p-4 p-3 login-button px-5 py-2 text-white bg-dasRed">
        Login
      </button>
      </form>
    </div> 
    </div>
    </section>
     ))}
    </>
  );
};
export default OtpVerification;
