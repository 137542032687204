import { useState, useEffect } from 'react';
import axios from 'axios';
import { DashNavVertical, Topbar } from '../../components/DashNav'
import { BsPhone, BsEnvelope } from "react-icons/bs";
import {  BsChevronDown } from "react-icons/bs";

const Requests = () => {
  const [contacts, setContacts] = useState([]);
    const [openStates, setOpenStates] = useState({});  // Use an object to track open states
  
    const toggleOpen = (id) => {  // Takes an ID
      setOpenStates({
        ...openStates,
        [id]: !openStates[id],  // Toggle the state of the given ID
      });
    };
  
    useEffect(() => {
      const fetchContacts = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/contact`);
          const sortedContacts = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setContacts(sortedContacts);
        } catch (error) {
          console.error('Failed to fetch contacts.');
        }
      };
    
      fetchContacts();
    }, []);
    
    
  return (
    <div className="h-screen flex overflow-hidden">
    <DashNavVertical/>
    <div className="flex flex-col w-full overflow-auto">
        <Topbar/>
                <div className="  overflow-auto">
                    <div className="h-screen  m-5 ">
                {contacts.map((contact) => (
                    <div key={contact._id} className="card bg-normalWhite rounded-lg p-5 box-shadow my-5 text-dasGrayDark">
                    <div onClick={() => toggleOpen(contact._id)}>
                      <div className="flex flex-row justify-between">
                    <h5 className='font-bold '>{contact.name}</h5>
                    <BsChevronDown/>
                    </div>
                    <p className='py-3' >  {contact.message}</p>
                    {openStates[contact._id] && (
                    <div>
                    <hr className='py-3'/>
                    <div className="flex flex-row gap-x-6 text-dasRed">
                    <div className='flex flex-row gap-x-2'>
                   <h1 className='my-auto font-bold' style={{fontSize:"24px"}}> <BsPhone/></h1>
                    <p>{contact.contactNumber}</p>
                    </div>
                    <div className='flex flex-row  gap-x-2'>
                   <h1 className='my-auto' style={{fontSize:"24px"}}><BsEnvelope/></h1> 
                    <p>{contact.email}</p>
                    </div>
                    </div>
                    </div>
                      )}
                    </div>
                    </div>
                    ))}
                    </div>
                    </div>
                    </div>
                    </div>
  )
}

export default Requests
