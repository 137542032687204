
import React, { useState, useEffect } from 'react';
import { DashNavVertical, Topbar } from '../../components/DashNav'
 import { BsSend } from "react-icons/bs";
 import { BsTrash3, BsPencilSquare  } from "react-icons/bs";
import axios from 'axios';
import { truncateText, useScreenSize, getTitleCharLimit   } from '../../utils/Truncate';
import { Link } from 'react-router-dom';

const BlogsHome = ( ) => { 
  const [posts, setPosts] = useState([]);
  const { isLargeScreen, isMediumScreen, isSmallScreen } = useScreenSize();
  const titleCharLimit = getTitleCharLimit(isLargeScreen, isMediumScreen, isSmallScreen);
useEffect(() => {
  fetch(`${process.env.REACT_APP_API_URL}/api/posts`)
    .then(response => response.json())
    .then(data => {
      const sortedPosts = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPosts(sortedPosts);
    })
    .catch(err => console.error(err));
}, []);

  
    const [redirectTo, setRedirectTo] = useState(null);

    useEffect(() => {
      if (redirectTo) {
        window.location.href = redirectTo;
      }
    }, [redirectTo]);
    const handleClickOpen = () => {
      setRedirectTo("/adminpanel/blogs/add-blogpost");
    };
    const deletePost = async (postId) => {
      const userConfirmed = window.confirm("Are you sure you want to delete this post?");
      if (userConfirmed) {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/posts/${postId}`);
          setPosts(posts.filter(post => post._id !== postId));  
          alert("Post deleted successfully!");
        } catch (error) {
          console.error(error);
          alert("Failed to delete post.");
        }
      }
    };
   
  return (
    <div className="h-screen flex overflow-hidden">
    <DashNavVertical/>
    <div className="flex flex-col w-full overflow-auto">
        <Topbar/>
                <div className="  overflow-auto">
                    <div className="h-screen  m-5 ">
                    <div className="grid grid-cols-3 gap-4">
                    {posts.map((post) => {
                        console.log(post._id)// Logging each post's ID
                        return (
        <div key={post._id} className="  rounded-lg bg-normalWhite box-shadow">
                 
            <img src={`${process.env.REACT_APP_API_URL}/${post.mainBanner}`} alt={post.altText} className='rounded-t-lg'/>
            <div className=' p-4'>
       <h2 className='font-bold'>{truncateText(post.title, titleCharLimit )}</h2> 
<div className='flex flex-row w-full gap-x-3 py-2 justify-end'>
<button onClick={() => deletePost(post._id)}> <BsTrash3 /></button>
<button>
<Link to={`/adminpanel/blogs/${post._id}/edit`} className='my-auto'>
            <BsPencilSquare/>
          </Link></button>
</div>
</div>
        </div>
        );
      })}
                    </div>

                {/** button */}
                    <div className="fixed right-4 bottom-10">
                        <button className='flex flex-row gap-x-2 bg-dasRed p-3 text-normalWhite rounded-3xl'
                        onClick={handleClickOpen}> 
                        <h1 className='text-xl my-auto' ><BsSend   /></h1>
                    <p>  Write an article</p>
                        </button>
                    </div>
                        </div>
                        </div>
                        </div>
                        </div>
      
 
  )
}

export default BlogsHome
