import React, { useState } from 'react';
 
import ReactPlayer from 'react-player';

const Article = () =>   {
    const videoLink = 'https://youtu.be/Uq6GJDVrNJo';
      return (
        <>
<ReactPlayer url={videoLink} width={640} height={360} controls={true} />
 
 </>
    );
  };
  

export default Article
