import React, { useEffect, useState } from 'react';
 

function DisplayAllBlog() {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetch('/api/posts')
      .then(response => response.json())
      .then(data => setPosts(data))
      .catch(err => console.error(err));
  }, []);

  return (
    <div>
      {posts.map((post, index) => (
        <div key={index}>
    <h2>{post.title}</h2>
   <p>{post.mainParagraph}</p>
    <p></p>
        </div>
      ))}
    </div>
  );
}

export default DisplayAllBlog;
