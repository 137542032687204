import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactPlayer from 'react-player';

const DisplayOneBlog =    ( ) => {
    const [post, setPost] = useState(null);
    const { id } = useParams();   
    useEffect(() => {
      fetch(`/api/posts/${id}`)
        .then(response => response.json())
        .then(data => setPost(data))
        .catch(err => console.error(err));
    }, [id]);   
  
    if (!post) {
      return <div>Loading...</div>;
    }

    return (
      <div>
      <h2>{post.title}</h2>
      <p> Main para{post.mainParagraph}</p>
      <p>Sub Para{post.subParagraph}</p>
      <img src={`http://localhost:8800/${post.mainBanner}`} alt={post.altText} className="banner-image"/>
      <p>Alt text{post.altText}</p>
      <p>{post.altTextSecondary}</p>
      <p>{post.metaTitle}</p>
      <p>{post.metaDescription}</p>
      <p>{post.metaKeywords}</p>
      <p>{post.youtubeVideo}</p>
      <ReactPlayer url={post.youtubeVideo} width={640} height={360} controls={true} />

      {post.images?.map((image, index) => (
                    <div className="w-2/12 h-2/12 ">
                  <div key={image} className='flex flex-row'>
                  {index === 0 && (
                <img src={`http://localhost:8800/${image}`} alt={post.altText} className="first-image" />
                 )}
               {index === 1 && (
                 <img src={`http://localhost:8800/${image}`} alt={post.altText} className="second-image" />
                 )}
                {index === 2 && (
               <img src={`http://localhost:8800/${image}`} alt={post.altText} className="hidemeformobile" />
               )}                    
                     </div>
                    </div>
                   ))}
<section className="section">
  {post.sections?.map((section, index) => (
    <div key={index}>
      <h2>{section.title}</h2>
      <p>{section.description}</p>
    </div>
  ))}
</section>
    </div>
    );
      }
      

export default DisplayOneBlog
