import React, { useState, useRef } from "react";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import BlogsHome from '../blogs/BlogsHome';

{/**
const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/contact`, { name, email, contactNumber, message });
      setName('');
      setEmail('');
      setContactNumber('');
      setMessage('');
      navigate('/'); // Navigate to the Thank You page
    } catch (error) {
      console.error('Failed to send message.');
    }
  };
 

  return (
    <form onSubmit={handleSubmit}>
      <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" required />
      <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" required />
      <input type="text" value={contactNumber} onChange={(e) => setContactNumber(e.target.value)} placeholder="Contact Number" required />
      <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message" required></textarea>
      <button type="submit">Send</button>
    </form>
  );
};
 */}

 const ContactForm = () => {
  const form = useRef();
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [contactNumber, setContactNumber] = useState("");
    const [isPhoneValid, setIsPhoneValid] = useState(true);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleEmailChange = (event) => {
      setEmail(event.target.value);
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      setIsEmailValid(emailRegex.test(event.target.value));
    };
  
    const handlePhoneChange = (event) => {
        setContactNumber(event.target.value);
      const phoneRegex = /^(\+\d{1,3}\s?)?(\(\d{1,3}\)\s?)?\d{1,4}[\s.-]?\d{1,4}[\s.-]?\d{1,9}$/;
      setIsPhoneValid(phoneRegex.test(event.target.value));
    };
  
    const sendEmail = (e) => {
      e.preventDefault();
      setIsLoading(true);
  
      const formData = new FormData(e.target);
      const formValues = Object.fromEntries(formData);
  
      emailjs
        .sendForm(
            "service_9gu7i4d",
            "template_fcz6wid",
            form.current,
            "tsevzYsapLKXn1dLD"
        )
        .then(() => {
          setIsSubmitted(true);
          navigate("/thankyou");
        })
        .catch((err) => {
          console.error("Failed to send email. Error: ", err);
        })
        .finally(() => {
          setIsLoading(false);
        });
  
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/contact`, formValues)
        .then(response => {
          console.log('Data successfully sent to server');
        })
        .catch(error => {
          console.error('Failed to send data to server. Error: ', error);
        });
    };
  
    if (isLoading) {
      return "";
    }
  
    if (isSubmitted) {
      return <BlogsHome />;
    }


  return (
    <div className="contact-form      ">
      <div className="d-flex flex-column contact-title justify-content-start">
 
      </div>
      <form className="px-lg-3 px-md-4 px-4  " ref={form} onSubmit={sendEmail}>
        <div className="py-md-3 py-3">
          <input
            className="no-styling py-md-3 py-2 input-padding label-inside  w-100"
            type="text"
            name="name"
            id="name"
            required
            placeholder=" Name"
          />
        </div>
        <div className="py-md-3  ">
          <input
            type=" email"
            name="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder=" Email"
            required
            className={`no-styling py-md-3 py-2 input-padding label-inside  w-100 ${
              !isEmailValid ? "input-error" : ""
            }`}
          />

          {!isEmailValid && (
            <p className="error-message">Please enter a valid email address</p>
          )}
        </div>
        <div className="py-md-3 py-3">
          <div className="d-flex  ">
            <input
              className={`no-styling py-md-3 py-2 input-padding label-inside   w-100 ${
                !isPhoneValid ? "input-error" : ""
              }`}
              type="tel"
              name="contactNumber"
              id="contactNumber"
              value={contactNumber}
              onChange={handlePhoneChange}
              placeholder=" Contact No (e.g.+971 xx xxx xxxx)"
              required
            />
          </div>
          {!isPhoneValid && (
            <p className="error-message">Please enter a valid phone number</p>
          )}
        </div>
        <div className="pt-md-5   py-3">
          <textarea
            className="py-md-3 py-2  input-padding label-inside border-full w-100"
            name="message"
            id="message"
            placeholder=" How can we help you?"
          ></textarea>
        </div>
        <div className="mx-auto pt-md-2 text-center ">
          <button type="submit" className="send p-2 semibold  ">
            Send
          </button>
        </div>
      </form>
    </div>
  );
};
export default ContactForm;
