 import React from 'react'
 
 const UpdateTest = () => {
   return (
     <div>
       
     </div>
   )
 }
 
 export default UpdateTest
 
{/*const [mainBanner, setMainBanner] = useState(null);
const [images, setImages] = useState([]);
  const [sections, setSections] = useState([{ subTitle: '', paragraph: '', sectionImage: null }]);*/}
   {/*const handleMainBannerChange = (event) => {
    const file = event.target.files[0];
    setImageSelected(file !== undefined);  // update imageSelected state
    setMainBanner(file || '');
  };
  const handleImageChange = (event) => {
    setImages([...event.target.files]);
    setImageSelectedthree(event.target.files.length > 0);
  };
  const handleSectionImageChange = (event, index) => {
    const newSections = [...sections];
    newSections[index].sectionImage = event.target.files[0];
    newSections[index].sectionImageFieldname = `sections[${index}][sectionImage]`; // new line
    setSections(newSections);
  };*/}